export class DateData {
    public before: Date;
    public after: Date;
    public rangeType: string;

    constructor(before: Date, after: Date, rangeType: string) {
        this.before = before;
        this.after = after;
        this.rangeType = rangeType;
    }

    public toLabel() {
        switch (this.rangeType) {
            case 'mensal':
                const moment = require('moment');
                return 'Em ' + moment(this.after).format('MMMM [de] YYYY');
            case 'personalizado':
                if (this.before === null && this.after !== null) {
                    return 'A partir ' + this.after.toLocaleDateString();
                } else if (this.before !== null && this.after === null) {
                    return 'Até ' + this.before.toLocaleDateString();
                } else if (this.before !== null && this.after !== null) {
                    return 'Entre ' + this.after.toLocaleDateString() + ' a ' + this.before.toLocaleDateString();
                }
                break;
        }
    }
}
