export class SortData {
    public field: {label: string, name: string} = {label: null, name: null};
    public ascending: boolean;

    constructor(label: string, name: string, ascending: boolean) {
        this.field = {
            label,
            name,
        };
        this.ascending = ascending;
    }

    public toLabel() {
        return this.field.label;
    }
}
