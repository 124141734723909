export class BooleanData {
    public status: boolean;
    public trueLabel: string;
    public falseLabel: string;

    constructor(status: boolean, trueLabel: string, falseLabel: string) {
        this.status = status;
        this.trueLabel = trueLabel;
        this.falseLabel = falseLabel;
    }

    public toLabel() {
        return this.status ? this.trueLabel : this.falseLabel;
    }
}
